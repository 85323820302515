export default {
  TITULO_MODAL_EDITAR: "Editar pagamento",
  NUMERO_CARTAO: "Número do Cartão",
  NOME_CARTAO: "Nome no cartão",
  DATA_EXPIRACAO: "Data de expiração",
  MES_VENCIMENTO: "Mês de Vencimento",
  ANO_VENCIMENTO: "Ano de Vencimento",
  CODIGO_SEGURANCA: "Código de segurança (CVV)",
  ENDERECO: "Endereço",
  COMPLEMENTO: "Complemento",
  BAIRRO: "Bairro",
  CIDADE: "Cidade",
  ESTADO: "Estado",
  CONSULTAR: "Atualizar status pagamento GSP",
  TITULO_ATIVAR: "Consultar pagamento GSP",
  CONFIRMACAO_CONSULTAR: "Deseja consultar pagamento",
  BOTAO_CONSULTAR: "Atualizar",
  ATUALIZAR_SUCESSO: "Status do pagamento atualizado com sucesso"
};
